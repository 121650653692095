/* Footer.css */
footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  footer p {
    margin: 0;
  }
  