.policy-container {
    max-width: 900px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .policy-container h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  section {
    margin-bottom: 40px;
  }
  
  section h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  section p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  