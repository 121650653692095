/* Add this for the headline */
h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  align-self: center;
  text-align: center;
}

/* Add styles for the three-dot button */
.three-dot-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 10px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.three-dot-button:hover {
  transform: scale(1.2);
}

.additional-filters {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease, max-height 0.5s ease;
  max-height: 0;
  overflow: hidden; /* Ensure content doesn't overflow */

}

.additional-filters.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 1;
  max-height: 300px;
}

/* Other styling adjustments */
.search-filter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.search-bar {
  width: 70%;
  padding: 12px;
  font-size: 1.2rem;
  border-radius: 25px;
  border: 1px solid #ccc;
}

.price-slider {
  width: 80% !important;
  margin-top: 20px;
}

.price-range-labels {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .search-bar, .price-slider, .price-range-labels {
    width: 100%;
  }
}
