/* CartPage.css */

.cart-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

/* Cart Items */
.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Cart Item Layout */
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  gap: 15px;
}

.cart-item-image {
  flex-shrink: 0;
}

.cart-item-image img {
  width: 150px;
  height: auto;
  border-radius: 10px;
}

.cart-item-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-item-details h3 {
  font-size: 1.5rem;
  margin: 0;
  color: #333;
}

.cart-item-price {
  font-size: 1.2rem;
  color: #666;
}

/* Quantity Controls */
.quantity-controls {
  display: flex;
  gap: 10px;
  align-items: center;
}

button{
  margin: 10px;
}

.quantity-controls button {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.quantity-controls button:hover {
  background-color: #0056b3;
}

/* Remove Button */
.remove-btn {
  padding: 12px 25px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.remove-btn:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Checkout Button */
.checkout-btn {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: white;
  font-size: 1.3rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-btn:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    text-align: center;
  }

  .cart-item-image {
    margin-bottom: 15px;
  }

  .cart-item-image img {
    width: 100%;
    max-width: 250px;
  }

  .quantity-controls {
    justify-content: center;
    margin-top: 10px;
  }

  .remove-btn {
    width: 100%;
    margin-top: 10px;
    padding: 12px;
  }
}
