/* General container styling */
.checkout-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Form styling */
.checkout-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group label {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.2s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
}

/* PayPal Section */
.paypal-section {
  margin-top: 20px;
}

.paypal-section h2 {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: #333;
}

/* Center PayPal buttons and limit their width */
#paypal-button-container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.paypal-button {
  max-width: 100%;
}

/* Order Summary section styling */
.cart-summary {
  margin-top: 30px;
  text-align: left;
}

.cart-summary h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #333;
}

.cart-summary p {
  font-size: 1.1rem;
  margin: 5px 0;
  color: #555;
}

.cart-summary strong {
  font-size: 1.2rem;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .checkout-container {
    padding: 15px;
  }

  .form-group label {
    font-size: 1rem;
  }

  .form-group input {
    font-size: 1rem;
    padding: 10px;
  }

  .paypal-section h2 {
    font-size: 1.2rem;
  }

  .cart-summary h2 {
    font-size: 1.3rem;
  }

  .cart-summary p {
    font-size: 1rem;
  }

  .cart-summary strong {
    font-size: 1.1rem;
  }

  /* Ensure PayPal button fits well on smaller screens */
  #paypal-button-container {
    max-width: 100%;
  }
}
