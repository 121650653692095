.admin-container {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
}

.admin-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.admin-tabs button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.admin-tabs button.active-tab {
  background-color: #0056b3;
}

.admin-tabs button:hover {
  background-color: #0056b3;
}

.admin-form, .product-table {
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-size: 1.2rem;
  color: #333;
}

.form-control {
  padding: 10px;
  font-size: 1.1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.submit-btn {
  padding: 12px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.product-table {
  border-collapse: collapse;
  width: 100%;
}

.product-table th, .product-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.product-table th {
  background-color: #f2f2f2;
}

.product-table td {
  font-size: 1rem;
}

.edit-btn, .delete-btn, .save-btn {
  padding: 10px 15px;
  margin-right: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.edit-btn {
  background-color: #28a745;
  color: white;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
}

.save-btn {
  background-color: #007bff;
  color: white;
}

.edit-btn:hover, .delete-btn:hover, .save-btn:hover {
  opacity: 0.9;
}
