/* App.css */

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Hides horizontal overflow */
  
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 600;
}

button {
  font-family: inherit;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* Ensuring the header sticks to the top */
header {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

/* Padding the main content to not overlap the sticky header */
.main-content {
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
