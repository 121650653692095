.product-detail-container {
  display: flex;
  flex-direction: row;
  padding: 50px;
  background-color: #f4f4f4;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image {
  width: 100%;
  max-width: 450px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-thumbnails {
  display: flex;
  margin-top: 15px;
}

.thumbnail {
  width: 80px;
  margin: 0 10px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.product-info {
  flex: 1;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-info h1 {
  font-size: 2.5rem;
  color: #333;
}

.sku {
  font-size: 1rem;
  color: #888;
  margin-bottom: 15px;
}

.price-info {
  font-size: 2.2rem;
  margin-bottom: 15px;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 10px;
}

.discounted-price {
  color: #28a745;
  font-weight: bold;
}

.quantity-section {
  display: flex;
  align-items: center;
}

.quantity-section button {
  padding: 5px 10px;
  font-size: 1.2rem;
}

.quantity-section input {
  width: 40px;
  text-align: center;
  margin: 0 10px;
  font-size: 1.2rem;
}

.action-section {
  display: flex;
  margin-bottom: 20px;
}

.add-to-cart, .buy-now {
  padding: 15px 40px;
  margin-right: 10px;
  background-color: #28a745;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart:hover, .buy-now:hover {
  background-color: #218838;
}

.product-description {
  font-size: 1.2rem;
  color: #555;
  margin-top: 20px;
}

.tags {
  font-size: 1.1rem;
  color: #777;
  margin-top: 10px;
}

.product-reviews {
  margin-top: 40px;
}

.product-reviews h3 {
  font-size: 1.6rem;
  color: #333;
}

.product-reviews p {
  font-size: 1.1rem;
  color: #666;
}

@media (max-width: 768px) {
  .product-detail-container {
    flex-direction: column;
    align-items: center;
  }

  .product-info {
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
  }

  .add-to-cart, .buy-now {
    width: 100%;
    max-width: 300px;
  }
}
