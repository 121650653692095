/* HomePage.css */

/* Hero Section */
.hero {
    background-image: url('../assets/pics/general/pic1.jpg');
    background-size: cover;
    background-position: center;
    padding: 100px 20px;
    text-align: center;
    color: #fff !important;
  }
  
  .hero-content {
    max-width: 600px;
    margin: 0 auto;
    color: aliceblue;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .explore-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #007BFF;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .explore-button:hover {
    background-color: #0056b3;
  }
  
  /* Featured Products */
  .featured-products {
    padding: 40px 20px;
    background-color: #f8f9fa;
  }
  
  .featured-products h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .product-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .product-card {
    background: white;
    padding: 20px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 200px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-card:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  /* Categories */
  .categories {
    padding: 40px 20px;
  }
  
  .categories h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .category-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .category-card {
    background: white;
    padding: 20px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 200px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .category-card:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .hero {
    position: relative;
  }
  
  .hero:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  }
  
  .hero-content {
    position: relative;
    z-index: 2; /* Ensure text is above overlay */
  }
  
  /* Featured Products */
.featured-products {
  padding: 40px 20px;
  background-color: #f8f9fa;
}

.featured-products h2 {
  text-align: center;
  margin-bottom: 20px;
}

.product-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.product-card {
  background: white;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-card img {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain; /* Ensures the image fits inside the container while maintaining aspect ratio */
  margin-bottom: 10px;
}

.product-card:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}
