/* Header.css */

/* General header styling */
header {
  background-color: #333;
  color: #fff;
  padding: 15px;
  position: relative;
}

/* Navigation Links */
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
}

nav ul li a:hover {
  text-decoration: underline;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px;
}

/* Hide navigation links for mobile */
.nav-links {
  display: flex;
  justify-content: space-evenly;
}

.nav-links.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #333;
  width: 100%;
  padding: 10px;
}

.nav-links li {
  text-align: center;
  margin: 10px 0;
}

/* Media query for mobile devices */
@media (max-width: 300px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  header{
    height: 50px;
  }

  .nav-links.active {
    display: flex;
  }
}


/* Add styling for the dropdown and triangle icon */
.products-dropdown {
  position: relative;
  display: inline-block;
}

.triangle-icon {
  font-size: 0.8rem;
  margin-left: 5px;
}

/* Dropdown Menu Styling */
.dropdown-menu {
  position: absolute;
  top: 25px;
  left: 0;
  background-color: #444;
  list-style-type: none;
  padding: 10px 0;
  display: none; /* Initially hidden */
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  z-index: 10; /* Make sure it stays above other elements */
  width: 150%;
}

/* Display dropdown when hovering over either the "Products" link or the dropdown itself */
.products-dropdown:hover .dropdown-menu {
  display: flex; /* Show the menu when hovering over the dropdown */
}

.dropdown-menu li {
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-menu li:hover {
  background-color: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dropdown-menu {
    position: relative;
    top: auto;
    left: auto;
  }
}
